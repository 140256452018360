import { useEffect } from "react";
import AdminNavBar from "./AdminNavBar";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/actions/userActions";
import { getAllTheQuestions } from "../../redux/actions/questionActions";
import { useState } from "react";


export default function DataPanel() {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const localUser = JSON.parse(localStorage.getItem('profile'))
    const { allUsers } = useSelector(state => state.user)
    const { allQuestions } = useSelector(state => state.question)
    const personales = allQuestions.filter((q) => q.type === "Personales" && q.user?.length > 0)
    const profundas = allQuestions.filter((q) => q.type === "Profundas" && q.user?.length > 0)
    const casuales = allQuestions.filter((q) => q.type === "Casuales" && q.user?.length > 0)
    const quePrefieresSituaciones = allQuestions.filter((q) => q.type === "Qué prefieres... (Situaciones)" && q.user?.length > 0)
    const quePrefieresCosas = allQuestions.filter((q) => q.type === "Qué prefieres... (Cosas)" && q.user?.length > 0)
    const anecdota = allQuestions.filter((q) => q.type === "Anécdota de..." && q.user?.length > 0)
    const retos = allQuestions.filter((q) => q.type === "Retos" && q.user?.length > 0)
    const quienDeTusAmigosEs = allQuestions.filter((q) => q.type === "Quién de tus amigos es..." && q.user?.length > 0)
    const picantesGrupales = allQuestions.filter((q) => q.type === "Picantes grupales (+18)" && q.user?.length > 0)
    const yoNuncaNunca = allQuestions.filter((q) => q.type === "Yo nunca nunca... (+18)" && q.user?.length > 0)
    const retoOShot = allQuestions.filter((q) => q.type === "Reto o shot (+18)" && q.user?.length > 0)
    const quePrefieresBizarro = allQuestions.filter((q) => q.type === "Qué Prefieres bizarro (+18)" && q.user?.length > 0)
    const creadasPorUsuarios = allQuestions.filter((q) => q.user?.length > 0)

    useEffect(() => {
        dispatch(getAllUsers())
        dispatch(getAllTheQuestions(setLoading))
    }, [])

    return (
        <div>
            <AdminNavBar />
            {
                loading ? <div className="d-flex justify-content-center"><h1 style={{color: '#df0e62'}}>Cargando...</h1></div> : allQuestions.length > 0 && localUser?.result?.admin === true ?
                <div className="dataPanelDiv">
                    <h4 style={{width: '300px', color: '#df0e62'}}>Cantidad de usuarios: {allUsers.length}</h4>
                    <table className="table table-striped" style={{width: '300px', color: '#df0e62'}}>
                        <thead>
                            <tr>
                                <th scope="col">Preguntas</th>
                                <th scope="col">Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Todas</th>
                                <td>{allQuestions.length}</td>
                            </tr>
                            <tr>
                                <th scope="row">Creadas por usuarios</th>
                                <td>{creadasPorUsuarios.length}</td>
                            </tr>
                            <tr>
                                <th scope="row">Personales</th>
                                <td>{personales.length}</td>
                            </tr>
                            <tr>
                                <th scope="row">Profundas</th>
                                <td>{profundas.length}</td>
                            </tr>
                            <tr>
                                <th scope="row">Casuales</th>
                                <td>{casuales.length}</td>
                            </tr>
                            <tr>
                                <th scope="row">Qué prefieres... (Situaciones)</th>
                                <td>{quePrefieresSituaciones.length}</td>
                            </tr>
                            <tr>
                                <th scope="row">Qué prefieres... (Cosas)</th>
                                <td>{quePrefieresCosas.length}</td>
                            </tr>
                            <tr>
                                <th scope="row">Anécdota de...</th>
                                <td>{anecdota.length}</td>
                            </tr>
                            <tr>
                                <th scope="row">Retos</th>
                                <td>{retos.length}</td>
                            </tr>
                    <tr>
                        <th scope="row">Quién de tus amigos es...</th>
                        <td>{quienDeTusAmigosEs.length}</td>
                    </tr>
                    <tr>
                        <th scope="row">Picantes grupales (+18)</th>
                        <td>{picantesGrupales.length}</td>
                    </tr>
                    <tr>
                        <th scope="row">Yo nunca nunca... (+18)</th>
                        <td>{yoNuncaNunca.length}</td>
                    </tr>
                    <tr>
                        <th scope="row">Reto o shot (+18)</th>
                        <td>{retoOShot.length}</td>
                    </tr>
                    <tr>
                        <th scope="row">Qué Prefieres bizarro (+18)</th>
                        <td>{quePrefieresBizarro.length}</td>
                    </tr>
                </tbody>
            </table>
            </div> : <div className="d-flex justify-content-center"><h1 style={{color: '#df0e62'}}>Cargando...</h1></div>
            }
        </div>
    )
}