import { useEffect, useReducer, useState } from "react";
import { TiDelete } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { createNickName } from "../../../redux/actions/userActions";
import Auth from "../Auth";
import MiniSpinner from "../Spinner/MiniSpinner";
import Modals from "./Modals";
import Swal from "sweetalert2";


export default function AuthModals({isOpenModal, closeModal, isOpenNickNameModal, openNickNameModal, closeNickNameModal}) {

    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem('profile'))
	let _id = user?.result?._id
    const [loading, setLoading] = useState(false)
	const initialNickname = {nickName: '' || null}
	const [nickName, setNickname] = useState(initialNickname)
    const navigateTo = useNavigate()

    const handleNickNameChange = (e) => {
        setNickname({...nickName, [e.target.name]: e.target.value})
    }

	const handleNickNameSubmit = (e) => {
        e.preventDefault()
        if(nickName.nickName.length < 3) {
            Swal.fire({
                title: "Error",
                text: 'El mínimo es de 3 caracteres!',
                icon: "error",
                background: "#1a1a1a",
                color: '#fff',
                timer: 3000,
            })
        } else if(nickName.nickName.length > 20) {
            Swal.fire({
                title: "Error",
                text: 'El máximo es de 20 caracteres!',
                icon: "error",
                background: "#1a1a1a",
                color: '#fff',
                timer: 3000,
            })
        } else {
            dispatch(createNickName(_id, nickName, closeNickNameModal, navigateTo, setLoading))
        }
    }

    return (
        <div>
            <Modals isOpenModal={isOpenModal} closeModal={closeModal}>
				<div className="d-flex">
					<p style={{color: '#df0e62', marginLeft: '50px'}}>Inicia sesión</p>
				    <div>
					    <button onClick={closeModal} style={{background: 'none', border: 'none', marginLeft: '25px', outline: 'none'}}>
							<TiDelete size={25} color="red" />
						</button>
					</div>
				</div>
                <h2>Inicia sesión para poder agregar preguntas!</h2>
				<Auth closeModal={closeModal} openNickNameModal={openNickNameModal}  />
            </Modals>
			<Modals isOpenModal={isOpenNickNameModal} closeModal={closeNickNameModal}>
				<h4>FELICITACIONES, TE HAS REGISTRADO EXITOSAMENTE!</h4>
                    {
                        loading ?
                        <MiniSpinner /> :
                        <div style={{backgroundColor: 'gray', borderRadius: '10px'}}>
                            <p className="mt-2 px-2">Crea tu nombre de usuario para que todos sepan que es tu pregunta!</p>
                            <form style={{marginBottom: '10px', marginLeft: '30px'}} className="text-align-center d-flex" onSubmit={handleNickNameSubmit} noValidate>
                                <textarea style={{backgroundColor: '#686868', borderRadius: '5px', textAlign: 'center', height: '40px', width: '190px', resize: 'none', textDecoration: 'none'}} autoComplete='off' name="nickName" className="form-control nickNameInput" placeholder="Escribe aquí" onChange={handleNickNameChange} />
						        <button style={{borderRadius: '5px'}} className="btn btn-success" type="submit">Enviar</button>
					        </form>
                        </div>
                    }
				
            </Modals>
        </div>
    )
}