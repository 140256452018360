import { Link, useLocation, useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx"
import { MdAdminPanelSettings } from "react-icons/md"
import { RiCloseFill} from "react-icons/ri"
import { useEffect, useState } from "react";
import decode from 'jwt-decode'
import { useModal } from "./Modals/useModal";
import AuthModals from "./Modals/AuthModals";
import { useDispatch } from "react-redux";
import { clearUser } from "../../redux/slices/userSlice";

export default function CellphoneNavBar() {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
	const navigateTo = useNavigate()
	const location = useLocation()
    const [isOpenModal, openModal, closeModal] = useModal(false);
	const [isOpenNickNameModal, openNickNameModal, closeNickNameModal] = useModal(false);
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch()

	const handleLogOut = () => {
        dispatch(clearUser())
        localStorage.removeItem('profile')
        navigateTo("/")
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

	useEffect(() => {
        const token = user?.token
        if(token) {
            const decodedToken = decode(token)
            if(decodedToken.exp * 2000 < new Date().getTime()) {
                handleLogOut()
            }
        }
        setUser(JSON.parse(localStorage.getItem('profile')))
    }, [location])

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-black d-block d-lg-none">
            <Link className="navbar-brand font-weight-bold text-white" to="/">PREGUNTATE.NET</Link>
            {
                !isOpen ?
                <button style={{background: 'none', border: 'none', outline: 'none'}} className="float-right text-white pt-2" type="button" onClick={toggleMenu}>
                    <RxHamburgerMenu color="white" size={25} />
                </button> :
                <button style={{background: 'none', border: 'none', outline: 'none'}} className="float-right text-white pt-2" type="button" onClick={toggleMenu}>
                    <RiCloseFill color="white" size={25} />
                </button>
                
            }
            <div className="text-center">
            {user?.result?.admin === true ? 
                <Link to="/panelDePreguntas" className="navbar-brand font-weight-bold text-white">
                    <MdAdminPanelSettings size={50} />
                </Link> : ''
            }
            {
				user ? <button style={{borderRadius: '30px', backgroundColor: '#df0e62', color: '#ffff'}} className="btn" onClick={handleLogOut}> {!user.result.name ? 'Cerrar sesión' : `@${user.result.name}`} </button>
                :
				<button style={{borderRadius: '30px'}} className="btn btn-success" onClick={openModal}>Inicia sesión</button>
			}
            </div>
            {
                isOpen ?
                <div style={{marginTop: '10px'}}>
                    <Link className="pb-0 navButton" to="/">Personales</Link>
                    <Link className="pb-0 navButton" to="/profundas">Profundas</Link>
                    <Link className="pb-0 navButton" to="/casuales">Casuales</Link>
                    <Link className="pb-0 navButton" to="/quePrefieresSituaciones">Qué prefieres... (Situaciones)</Link>
                    <Link className="pb-0 navButton" to="/quePrefieresCosas">Qué prefieres... (Cosas)</Link>
                    <Link className="pb-0 navButton" to="/anecdota">Anécdota de...</Link>
                    {/* <Link className="pb-0 navButton" to="/soyMasDe">Soy más de...</Link> */}
                    {/* <Link className="pb-0 navButton" to="/topTres">Top 3...</Link> */}
                    <Link className="pb-0 navButton" to="/retos">Retos</Link>
                    <Link className="pb-0 navButton" to="/quienDeTusAmigosEs">Quién de tus amigos es...</Link>
                    {/* <Link className="pb-0 navButton" to="/matarCogerOCasarse">Matar, coger o casarse (Versión Argentina)</Link> */}
                    {/* <Link className="pb-0 navButton" to="/frasesQueSePuedenDecirEnElSexoY">Frases que se pueden decir en el sexo y…</Link> */}
                    <Link className="pb-0 navButton" to="/picantes">Picantes grupales (+18)</Link>
                    <Link className="pb-0 navButton" to="/yoNuncaNunca">Yo nunca nunca... (+18)</Link>
                    <Link className="pb-0 navButton" to="/retoOShot">Reto o shot (+18)</Link>
                    <Link className="pb-0 navButton" to="/quePrefieresBizarro">Qué prefieres bizarro (+18)</Link>
                </div> : ''
            }
            <AuthModals isOpenModal={isOpenModal} closeModal={closeModal} isOpenNickNameModal={isOpenNickNameModal} openNickNameModal={openNickNameModal} closeNickNameModal={closeNickNameModal} />
        </nav>
    )
}