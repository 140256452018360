import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Pagination from "../tools/Pagination"
import { getUnconfirmedQuestions, deleteUnconfirmedQuestion } from "../../redux/actions/unconfirmedQuestionActions"
import { useReducer } from 'react';
import { MdGppGood, MdGppBad } from 'react-icons/md'
import AdminNavBar from "./AdminNavBar";
import { addQuestion } from "../../redux/actions/questionActions";
import FilterUnconfirmedType from "../tools/sort/FilterUnconfirmedType";

export default function UnconfirmedQuestionsPanel() {

    const dispatch = useDispatch()
    const { filteredUnconfirmedQuestions } = useSelector(state => state.unconfirmedQuestion)
    let [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(8)
    const [localUser, setLocalUser] = useState(JSON.parse(localStorage.getItem('profile')))
	const max = Math.ceil(filteredUnconfirmedQuestions?.length / perPage)
    const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
    const [loading, setLoading] = useState(false)

    if(page > max) {
        page = max
    }

    useEffect(() => {
        dispatch(getUnconfirmedQuestions(setLoading))
    }, [reducerValue])

    return (
        <div>
            <AdminNavBar />
            {
                localUser?.result?.admin === true && !loading ?
                <div className="nav justify-content-center mb-3">
                    <Pagination page={page} setPage={setPage} max={max} />
                    <FilterUnconfirmedType setPage={setPage} filteredUnconfirmedQuestions={filteredUnconfirmedQuestions} />
                </div> : ''
            }
            {
				loading === true ? <div className="d-flex justify-content-center"><h1 style={{color: '#df0e62'}}>Cargando...</h1></div> : filteredUnconfirmedQuestions.length > 0 && localUser?.result?.admin === true ?
					filteredUnconfirmedQuestions
						.slice((page - 1) * perPage, (page - 1) * perPage + perPage)
						.map((q, i) => {
							let _id = q._id
                            let questionData = { text: q.text, type: q.type, user: q.user, userId: q.userId, _id: q._id }

							return (
                                <div className="card d-inline-flex ms-4 mb-3" style={{width: '19rem', height: 'auto', backgroundColor: '#1a1a1a', color: '#fff', marginLeft: '30px'}} key={i}>
                                <div className="card-body">
                                    <div className="d-flex"><h5 className="card-title  mr-4">{q?.type}</h5><p> (Creada por {q?.user}) </p></div>
                                    <p> {q?.text}  </p>
                                    <div className="d-flex text-center justify-content-center align-center">
                                        <button style={{background: 'none', border: 'none', outline: 'none'}} onClick={() => dispatch(addQuestion(questionData, forceUpdate))}><MdGppGood size={50} color="green" /></button>
                                        <button style={{background: 'none', border: 'none', outline: 'none'}} onClick={() => dispatch(deleteUnconfirmedQuestion(_id, forceUpdate))}><MdGppBad size={50} color="red" /></button>
                                    </div>
                                </div>
                            </div>
								)
							}) : <div className="d-flex justify-content-center"><h1 style={{color: '#df0e62'}}>No hay preguntas!</h1></div>
					}
                    {
                        localUser?.result?.admin === true && !loading ?
                        <Pagination page={page} setPage={setPage} max={max} /> : ''
                    }
        </div>
    )
}