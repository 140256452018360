import { useDispatch } from "react-redux";
import { filterUnconfirmedByType } from "../../../redux/slices/unconfirmedQuestionSlice";


export default function FilterUnconfirmedType({setPage, filteredUnconfirmedQuestions}) {

    let dispatch = useDispatch()

    function handleFilter(e) {
        const value = e.target.value
        setPage(1)
        dispatch(filterUnconfirmedByType(value))
    }

    return (
        <div className="select mt-3" style={{width: '320px', height: '30px', paddingLeft: '5px', color: 'white'}}>
            {filteredUnconfirmedQuestions.length}
            <select style={{marginLeft: '5px'}} defaultValue="All" onChange={handleFilter} >
                <option value="All"> Todas </option>
                <option value="Personales"> Personales </option>
                <option value="Profundas" > Profundas </option>
                <option value="Casuales" > Casuales </option>
                <option value="Qué prefieres... (Situaciones)" > Qué prefieres... (Situaciones) </option>
                <option value="Qué prefieres... (Cosas)" > Qué prefieres... (Cosas) </option>
                <option value="Anécdota de..." > Anécdota de... </option>
                {/* <option value="Soy más de..." > Soy más de... </option> */}
                {/* <option value="Top 3..." > Top 3... </option> */}
                <option value="Retos" > Retos </option>
                <option value="Quién de tus amigos es..." > Quién de tus amigos es... </option>
                <option value="Picantes grupales (+18)" > Picantes grupales (+18) </option>
                <option value="Yo nunca nunca... (+18)" > Yo nunca nunca... (+18) </option>
                <option value="Reto o shot (+18)" > Reto o shot (+18) </option>
                <option value="Qué Prefieres bizarro (+18)" > Qué Prefieres bizarro (+18) </option>
                {/* <option value="Matar, coger o casarse (versión argentina)" > Matar, coger o casarse (versión argentina) </option> */}
                {/* <option value="Frases que se pueden decir en el sexo y..." > Frases que se pueden decir en el sexo y... </option> */}
            </select>
        </div>
    )
}