import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getYoNuncaNunca } from "../../redux/actions/questionActions";
import { clearQuestions } from "../../redux/slices/questionSlice";
import CellphoneNavBar from "../tools/CellphoneNavBar";
import QuestionsContainer from "../tools/QuestionsContainer";

export default function YoNuncaNunca() {

	const { filteredQuestions } = useSelector(state => state.question)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(clearQuestions())
		dispatch(getYoNuncaNunca())
	}, [dispatch])

    return (
        <div className="goto-here">
            <CellphoneNavBar />
			<QuestionsContainer filteredQuestions={filteredQuestions} />
        </div>
    )
}