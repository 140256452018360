import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdAdminPanelSettings } from "react-icons/md"
import { useEffect, useReducer, useState } from "react";
import decode from 'jwt-decode'
import { useModal } from "./Modals/useModal";
import AuthModals from "./Modals/AuthModals";
import { clearUser } from "../../redux/slices/userSlice";
import { useDispatch } from "react-redux";

export default function NavBar() {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
	const location = useLocation()
	const [isOpenModal, openModal, closeModal] = useModal(false);
	const [isOpenNickNameModal, openNickNameModal, closeNickNameModal] = useModal(false);
	const navigateTo = useNavigate()
	const dispatch = useDispatch()

	const handleLogOut = () => {
		dispatch(clearUser())
        localStorage.removeItem('profile')
		navigateTo("/")
    }

	useEffect(() => {
        const token = user?.token
        if(token) {
            const decodedToken = decode(token)
            if(decodedToken.exp * 2000 < new Date().getTime()) {
                handleLogOut()
            }
        }
        setUser(JSON.parse(localStorage.getItem('profile')))
    }, [location])

    return (
        <div className="col-3 d-none d-lg-block">
			<div className="p-4 d-flex" style={{width: '500px'}}>
	            <Link to="/" className="h5 text-white font-weight-bold mr-2">PREGUNTATE.NET</Link>
				{user?.result?.admin === true ? 
				    <Link to="/panelDePreguntas" className="h5 text-white font-weight-bold mr-2" style={{marginTop: '-10px'}}>
					    <MdAdminPanelSettings size={50} />
					</Link> : ''
				}
				{
					user ? <button style={{borderRadius: '30px', marginTop: '-10px', backgroundColor: '#df0e62', color: '#ffff'}} className="btn text-center" onClick={handleLogOut}> {!user.result.name ? 'Cerrar sesión' : `@${user.result.name}`} </button> 
					:
					<button style={{borderRadius: '30px', marginTop: '-10px'}} className="btn btn-success text-center" onClick={openModal}>Inicia sesión</button>
				}
            </div>
            <div style={{overflowY: 'scroll', height: '430px'}} className="d-flex flex-column mr-5">
		        <div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/">Personales</Link>
	            </div>
		        <div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/profundas">Profundas</Link>
	            </div>
		        <div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/casuales">Casuales</Link>
	            </div>
		        <div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/quePrefieresSituaciones">Qué prefieres... (Situaciones)</Link>
	            </div>
		        <div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/quePrefieresCosas">Qué prefieres... (Cosas)</Link>
	            </div>
		        <div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/anecdota">Anécdota de...</Link>
	            </div>
		        {/* <div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/soyMasDe">Soy más de...</Link>
	            </div> */}
		        {/* <div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/topTres">Top 3...</Link>
	            </div> */}
		        <div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/retos">Retos</Link>
	            </div>
		        <div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/quienDeTusAmigosEs">Quién de tus amigos es...</Link>
	            </div>
				<div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/picantes">Picantes grupales (+18)</Link>
	            </div>
				<div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/yoNuncaNunca">Yo nunca nunca... (+18)</Link>
	            </div>
				<div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/retoOShot">Reto o shot (+18)</Link>
	            </div>
				<div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/quePrefieresBizarro">Qué prefieres bizarro (+18)</Link>
	            </div>
		        {/* <div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/matarCogerOCasarse">Matar, coger o casarse (Versión Argentina)</Link>
	            </div> */}
		        {/* <div className="px-2 py-0">
				    <Link className="nav-link pb-0 navButton" to="/frasesQueSePuedenDecirEnElSexoY">Frases que se pueden decir en el sexo y…</Link>
	            </div> */}
	        </div>
			<AuthModals isOpenModal={isOpenModal} closeModal={closeModal} isOpenNickNameModal={isOpenNickNameModal} openNickNameModal={openNickNameModal} closeNickNameModal={closeNickNameModal} />
		</div>
    )
}