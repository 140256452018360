/* import { useState } from "react"; */
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

export default function Pagination({page, setPage, max}) {

    const nextPage = () => {
        if(page < max) {
            setPage(page + 1)
        }
    }
    const prevPage = () => {
        if(page > 1) {
            setPage(page - 1)
        }
    }

    return (
        <div className="d-flex align-items-center justify-content-center">
            <button style={{background: 'none', border: 'none', outline: 'none'}} disabled={page === 1} onClick={prevPage}> <BsFillArrowLeftCircleFill size={30} color="white" /> </button>
            <p style={{fontFamily: '"Oswald", arial, sans-serif'}} className="text-center ms-2 mt-3"> {page} DE {max} </p>
            <button style={{background: 'none', border: 'none', outline: 'none'}} onClick={nextPage} disabled={page === parseInt(max)}> <BsFillArrowRightCircleFill size={30} color="white" /> </button>
        </div>
    )
}