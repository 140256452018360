import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Pagination from "../tools/Pagination"
import { addAdminQuestion, deleteQuestion, deleteUserQuestion, getAllTheQuestions } from "../../redux/actions/questionActions"
import { useReducer } from 'react';
import { MdDeleteForever } from 'react-icons/md'
import FilterType from "../tools/sort/FilterType";
import AdminNavBar from "./AdminNavBar";
import Swal from "sweetalert2";
import { useModal } from "../tools/Modals/useModal";
import QuestionModals from "../tools/Modals/QuestionModals";
import AdminSortQuestions from "../tools/sort/AdminSortQuestions";

export default function QuestionsPanel() {

    const dispatch = useDispatch()
    const { adminFilteredQuestions } = useSelector(state => state.question)
    let [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(8)
    const localUser = JSON.parse(localStorage.getItem('profile'))
    const [search, setSearch] = useState('')
    const filtered = adminFilteredQuestions.filter(q => q.text.toLowerCase().includes(search.toLowerCase()))
	const max = Math.ceil(adminFilteredQuestions?.length / perPage)
    const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
    const initialState = { text: '', type: '' }
	const [questionData, setQuestionData] = useState(initialState)
    const [isOpenQuestionModal, openQuestionModal, closeQuestionModal] = useModal(false);
    const [loading, setLoading] = useState(false)

    if(page > max) {
        page = max
    }

    useEffect(() => {
        dispatch(getAllTheQuestions(setLoading))
    }, [reducerValue])

    function filteredQuestion() {
        if(search.length === 0) {
            return adminFilteredQuestions
        } 
        if(filtered.length === 0) {
            Swal.fire({
                title: "Error",
                text: 'Esa pregunta no existe!',
                icon: "error",
                background: "#1a1a1a",
                color: '#fff',
                timer: 2000,
            });
            setPage(1)
            setSearch("")
        } 
        return filtered
    }

    function handleOnSearch(e) {
        setSearch(e.target.value)
        setPage(1) 
    }

    const handleChange = (e) => {
        setQuestionData({...questionData, [e.target.name]: e.target.value})
    }

    const handleSelect = (e) => {
    
        setQuestionData({
            ...questionData,
            type: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(questionData.text.length < 4) {
			Swal.fire({
				title: "Error",
				text: "Debe tener un mínimo de 4 caracteres!",
				icon: "error",
				background: "#1a1a1a",
				color: '#fff',
				timer: 3000,
			})
		} else if(questionData.text.length > 200) {
            Swal.fire({
				title: "Error",
				text: "Debe tener un máximo de 200 caracteres!",
				icon: "error",
				background: "#1a1a1a",
				color: '#fff',
				timer: 3000,
			})
		} else if(questionData.type.length < 1) {
            Swal.fire({
				title: "Error",
				text: "Elige una categoría!",
				icon: "error",
				background: "#1a1a1a",
				color: '#fff',
				timer: 3000,
			})
        } else {
            dispatch(addAdminQuestion(questionData, forceUpdate, e, setQuestionData, initialState, setLoading))
        }
    }

    return (
        <div>
            <AdminNavBar />
            {  
                !loading && localUser?.result?.admin === true ?
                <div className="nav justify-content-center mb-3">
                <label className="form-label mt-2">
                    <input style={{fontFamily: '"Oswald", arial, sans-serif'}} autoComplete="off" className="form-control" onChange={handleOnSearch} placeholder="Buscar pregunta" type="text" value={search} />
                </label>
                <Pagination page={page} setPage={setPage} max={max} />
                <FilterType setPage={setPage} filteredQuestions={adminFilteredQuestions} />
                <AdminSortQuestions setPage={setPage} />
                <button style={{marginLeft: '10px', marginTop: '10px', borderRadius: '30px', height: '40px'}} className="btn btn-primary text-center" onClick={openQuestionModal}>Agregar</button>
            </div>  : ''
            }
            {
				!loading && localUser?.result?.admin === true ?
                    filteredQuestion()
					.slice((page - 1) * perPage, (page - 1) * perPage + perPage)
					.map((q, i) => {
						let _id = q._id
                        let user = q?.user

						return (
                                <div className="card d-inline-flex ms-4 mb-3" style={{width: '19rem', height: 'auto', backgroundColor: '#1a1a1a', color: '#fff', marginLeft: '30px'}} key={i}>
                                    <div className="card-body">
                                        <div className="d-flex"><h5 className="card-title  mr-4">{q?.type}</h5>{q.user ? <p> (Creada por {q?.user}) </p> : ''}</div>
                                            <p> {q?.text}  </p>
                                            <div className="d-flex text-center justify-content-center align-center">
                                            <p className="mr-4">Likes: {q?.likes} </p>
                                            <p className="mr-4">Dislikes: {q?.dislikes} </p>
                                            { user ? <button style={{background: 'none', border: 'none', outline: 'none'}} onClick={() => dispatch(deleteUserQuestion(_id, forceUpdate, user))}><MdDeleteForever size={50} color="red" /></button> : <button style={{background: 'none', border: 'none', outline: 'none'}} onClick={() => dispatch(deleteQuestion(_id, forceUpdate))}><MdDeleteForever size={50} color="red" /></button>}
                                        </div>
                                    </div>
                                </div>
								)
					}) : <div className="d-flex justify-content-center"><h1 style={{color: '#df0e62'}}>Cargando...</h1></div>
			}
            {
                !loading && localUser?.result?.admin === true ?
                <Pagination page={page} setPage={setPage} max={max} /> : ''
            }
            <QuestionModals isOpenQuestionModal={isOpenQuestionModal} closeQuestionModal={closeQuestionModal} handleSubmit={handleSubmit} handleSelect={handleSelect} handleChange={handleChange} loading={loading} />
        </div>
    )
}