import { TiDelete } from "react-icons/ti";
import Modals from "./Modals";
import MiniSpinner from "../Spinner/MiniSpinner"


export default function QuestionModals({isOpenQuestionModal, closeQuestionModal, handleSubmit, handleSelect, handleChange, loading}) {

    return (
        <Modals isOpenModal={isOpenQuestionModal} closeModal={closeQuestionModal}>
				<div className="d-flex">
					    <p style={{color: '#df0e62', marginLeft: '50px'}}>Agrega tu pregunta</p>
					    <div>
							<button onClick={closeQuestionModal} style={{background: 'none', border: 'none', marginLeft: '25px', outline: 'none'}}>
								<TiDelete size={25} color="red" />
							</button>
						</div>
					</div>
                    <h4>Agrega tu pregunta a la categoría que desees!</h4>
                    {
                        loading ?
                        <MiniSpinner /> :
                        <form onSubmit={handleSubmit} noValidate>
                            <div className="select mb-2 mt-2" style={{width: 'auto'}}>
                                <select defaultValue="Elige" onChange={handleSelect} >
                                    <option value="Elige" disabled> Elige una categoría </option>
                                    <option value="Picantes grupales (+18)" > Picantes grupales (+18) </option>
                                    <option value="Yo nunca nunca... (+18)" > Yo nunca nunca... (+18) </option>
                                    <option value="Reto o shot (+18)" > Reto o shot (+18) </option>
                                    <option value="Qué Prefieres bizarro (+18)" > Qué Prefieres bizarro (+18) </option>
                                    <option value="Personales"> Personales </option>
                                    <option value="Profundas" > Profundas </option>
                                    <option value="Casuales" > Casuales </option>
                                    <option value="Qué prefieres... (situaciones)" > Qué prefieres... (Situaciones) </option>
                                    <option value="Qué prefieres... (cosas)" > Qué prefieres... (Cosas) </option>
                                    <option value="Anécdota de..." > Anécdota de... </option>
                                    {/* <option value="Soy más de..." > Soy más de... </option> */}
                                    {/* <option value="Top 3..." > Top 3... </option> */}
                                    <option value="Retos" > Retos </option>
                                    <option value="Quién de tus amigos es..." > Quién de tus amigos es... </option>
                                    {/* <option value="Matar, coger o casarse (versión argentina)" > Matar, coger o casarse (versión argentina) </option> */}
                                    {/* <option value="Frases que se pueden decir en el sexo y..." > Frases que se pueden decir en el sexo y... </option> */}
                                </select>
                            </div>
					        <textarea style={{backgroundColor: '#686868', borderRadius: '5px', textAlign: 'center', height: '87px', width: '320px', resize: 'none'}} autoComplete='off' name="text" className="form-control nickNameInput" placeholder="Escribe tu pregunta" onChange={handleChange} />
						    <button style={{borderRadius: '5px', textDecoration: 'none'}} className="btn btn-success mt-2" type="submit">Enviar</button>
					    </form>
                    }
        </Modals>
    )
}