import { useState } from "react";
import { TiDelete } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { addUnconfirmedQuestion } from "../../../redux/actions/unconfirmedQuestionActions";
import { orderByLikes, orderByDislikes, filterMyQuestions, orderRandom, orderRecents } from "../../../redux/slices/questionSlice"
import Auth from "../Auth";
import Modals from "../Modals/Modals";
import { useModal } from "../Modals/useModal";

export default function SortQuestions({setPage, filteredQuestions}) {

    let dispatch = useDispatch()
    const localUser = JSON.parse(localStorage.getItem('profile'))
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [isOpenNickNameModal, openNickNameModal, closeNickNameModal] = useModal(false);
    const [isOpenNoQuestionsModal, openNoQuestionsModal, closeNoQuestionsModal] = useModal(false);
    const initialState = { text: '', type: filteredQuestions[0]?.type }
	const [questionData, setQuestionData] = useState(initialState)

    function handleChange(e){
        let value = e.target.value;
        if(value === "menos_likes" || value === "mas_likes") {
            setPage(1)
            dispatch(orderByLikes(value))
        } 
        if(value === "menos_dislikes" || value === "mas_dislikes") {
            setPage(1)
            dispatch(orderByDislikes(value))
        } 
        if(value === "aleatorio") {
            setPage(1)
            dispatch(orderRandom())
        }
        if(value === "recientes") {
            setPage(1)
            dispatch(orderRecents())
        }
        if(value === "mis_preguntas") {
            setPage(1)
            dispatch(filterMyQuestions(value))
            if(!localUser) {
                openModal()
            }
        }
    } 

    const handleQuestionChange = (e) => {
        setQuestionData({type: filteredQuestions[0]?.type, likes: 0, dislikes: 0, user: localUser.result.name, ips: [], [e.target.name]: e.target.value})
    }

	const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(addUnconfirmedQuestion(questionData, closeNoQuestionsModal, e, setQuestionData, initialState))
    }

    return (
        <div>
            <div className="select mb-3 selectResponsive" style={{width: '150px', lineHeight: '2'}}>
                <select className="ms-2 mr-3" defaultValue="aleatorio" onChange={handleChange}>
                    <option style={{textAlign: 'center'}} value="aleatorio"> Aleatorio </option>
                    <option style={{textAlign: 'center'}} value="recientes" > Recientes </option>
                    <option style={{textAlign: 'center'}} value="mas_likes"> Mejor votadas</option>
                    <option style={{textAlign: 'center'}} value="menos_likes" > Menos votadas </option>
                    <option style={{textAlign: 'center'}} value="mis_preguntas"> Mis preguntas </option>
                </select>
            </div>
            <Modals isOpenModal={isOpenModal} closeModal={closeModal}>
                <div className="d-flex">
                    <p style={{color: '#df0e62', marginLeft: '60px', height: '10px'}}>Inicia sesión</p>
                    <div>
                        <button onClick={closeModal} style={{background: 'none', border: 'none', marginLeft: '50px', outline: 'none'}}>
                            <TiDelete size={25} color="red" />
                        </button>
                    </div>
                </div>
                <h2>Inicia sesión para poder filtrar tus preguntas!</h2>
                <Auth closeModal={closeModal} openNickNameModal={openNickNameModal}  />
            </Modals>
            <Modals isOpenNoQuestionsModal={isOpenNoQuestionsModal} closeModal={closeNoQuestionsModal}>
				<div className="d-flex">
					<p style={{color: '#df0e62', marginLeft: '50px'}}>Agrega tu pregunta</p>
					<div>
						<button onClick={closeNoQuestionsModal} style={{background: 'none', border: 'none', marginLeft: '25px', outline: 'none'}}>
							<TiDelete size={25} color="red" />
						</button>
					</div>
				</div>
                <h4>{`Agrega tu pregunta a la sección ${filteredQuestions[0]?.type}`}</h4>
				<form className="d-flex" onSubmit={handleSubmit} noValidate>
					<textarea style={{backgroundColor: '#686868', borderRadius: '5px', textAlign: 'center', height: '87px', width: '250px', resize: 'none'}} autoComplete='off' name="text" className="form-control nickNameInput" placeholder="Escribe tu pregunta" onChange={handleQuestionChange} />
					<button style={{borderRadius: '5px'}} className="btn btn-success" type="submit">Enviar</button>
				</form>
            </Modals>
        </div>
    )
}