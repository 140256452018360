import { createSlice } from "@reduxjs/toolkit";

export const questionSlice = createSlice({
    name: "questions",
    initialState: {
        allQuestions: [],
        questions: [],
        filteredQuestions: [],
        adminFilteredQuestions: [],
        ip: "",
    },
    reducers: {
        getAllQuestions: (state, action) => {
            state.allQuestions = action.payload
            state.adminFilteredQuestions = action.payload
        },
        getQuestions: (state, action) => {
            state.questions = action.payload
        },
        getFilteredQuestions: (state, action) => {
            state.filteredQuestions = action.payload
        },
        getUserIp: (state, action) => {
            state.ip = action.payload
        },
        clearQuestions: (state) => {
            state.questions = []
            state.filteredQuestions = []
        },
        filterByType: (state, {payload}) => {
            const questionType = state.allQuestions
            const filter = payload === 'All' ? state.allQuestions : payload === 'Users' ? questionType.filter((q) => q.user?.length > 0) : questionType.filter(q => ((q.type) || []).includes(payload))
            return {
                ...state,
                adminFilteredQuestions: filter
            }
        },
        filterMyQuestions: (state, {payload}) => {
            const localUser = JSON.parse(localStorage.getItem('profile'))
            if(localUser) {
                const myQuestions = state.questions
                const filter = payload === 'mis_preguntas' ? myQuestions.filter(m => m.userId === localUser.result._id) : state.filteredQuestions
                if(filter.length < 1) {
                    return {
                        ...state,
                        filteredQuestions: [{text: "Todavía no tienes preguntas en esta categoría!", type: state.questions[0].type}]
                    }
                } else {
                    return {
                        ...state,
                        filteredQuestions: filter,
                    }
                }
            } else {
                if(payload === "mis_preguntas") {
                    return {
                        ...state,
                        filteredQuestions: [{text: "Inicia sesión para poder filtrar tus preguntas!", type: state.questions[0].type}]
                    }
                }
            } 
        },
        orderByLikes: (state, action) => {
            const orderQuestionLikes = action.payload === "menos_likes" ?
                state.questions.slice().sort(function(a, b) {
                    if(parseInt(a.likes) < parseInt(b.likes)) {return -1}
                    if(parseInt(b.likes < a.likes)) {return 1}
                    return 0;
                }) : 
                state.questions.slice().sort(function(a, b) {
                    if(parseInt(a.likes) > parseInt(b.likes)) {return -1}
                    if(parseInt(a.likes) > parseInt(b.likes)) {return 1}
                    return 0;
        })
        return {
            ...state,
            filteredQuestions: orderQuestionLikes
        }
        },
        orderByLikesAdmin: (state, action) => {
            const orderQuestionLikes = action.payload === "menos_likes" ?
                state.adminFilteredQuestions.slice().sort(function(a, b) {
                    if(parseInt(a.likes) < parseInt(b.likes)) {return -1}
                    if(parseInt(b.likes < a.likes)) {return 1}
                    return 0;
                }) : 
                state.adminFilteredQuestions.slice().sort(function(a, b) {
                    if(parseInt(a.likes) > parseInt(b.likes)) {return -1}
                    if(parseInt(a.likes) > parseInt(b.likes)) {return 1}
                    return 0;
        })
        const orderQuestionLikesAdmin = action.payload === "menos_likes" ?
                state.allQuestions.slice().sort(function(a, b) {
                    if(parseInt(a.likes) < parseInt(b.likes)) {return -1}
                    if(parseInt(b.likes < a.likes)) {return 1}
                    return 0;
                }) : 
                state.allQuestions.slice().sort(function(a, b) {
                    if(parseInt(a.likes) > parseInt(b.likes)) {return -1}
                    if(parseInt(a.likes) > parseInt(b.likes)) {return 1}
                    return 0;
        })
        return {
            ...state,
            adminFilteredQuestions: orderQuestionLikes,
            allQuestions: orderQuestionLikesAdmin
        }
        },
        orderByDislikes: (state, action) => {
            const orderQuestionDislikes = action.payload === "menos_dislikes" ?
                state.questions.slice().sort(function(a, b) {
                    if(parseInt(a.dislikes) < parseInt(b.dislikes)) {return -1}
                    if(parseInt(b.dislikes < a.dislikes)) {return 1}
                    return 0;
                }) : 
                state.questions.slice().sort(function(a, b) {
                    if(parseInt(a.dislikes) > parseInt(b.dislikes)) {return -1}
                    if(parseInt(a.dislikes) > parseInt(b.dislikes)) {return 1}
                    return 0;
        })
        return {
            ...state,
            filteredQuestions: orderQuestionDislikes
        }
        },
        orderByDislikesAdmin: (state, action) => {
            const orderQuestionDislikes = action.payload === "menos_dislikes" ?
                state.adminFilteredQuestions.slice().sort(function(a, b) {
                    if(parseInt(a.dislikes) < parseInt(b.dislikes)) {return -1}
                    if(parseInt(b.dislikes < a.dislikes)) {return 1}
                    return 0;
                }) : 
                state.adminFilteredQuestions.slice().sort(function(a, b) {
                    if(parseInt(a.dislikes) > parseInt(b.dislikes)) {return -1}
                    if(parseInt(a.dislikes) > parseInt(b.dislikes)) {return 1}
                    return 0;
        })
        const orderQuestionDislikesAdmin = action.payload === "menos_dislikes" ?
                state.allQuestions.slice().sort(function(a, b) {
                    if(parseInt(a.dislikes) < parseInt(b.dislikes)) {return -1}
                    if(parseInt(b.dislikes < a.dislikes)) {return 1}
                    return 0;
                }) : 
                state.allQuestions.slice().sort(function(a, b) {
                    if(parseInt(a.dislikes) > parseInt(b.dislikes)) {return -1}
                    if(parseInt(a.dislikes) > parseInt(b.dislikes)) {return 1}
                    return 0;
        })
        return {
            ...state,
            adminFilteredQuestions: orderQuestionDislikes,
            allQuestions: orderQuestionDislikesAdmin,
        }
        },
        orderRandom: (state) => {
            function shuffleArray(questions){
                questions.sort(()=> Math.random() - 0.5);
            }
            shuffleArray(state.filteredQuestions)
        },
        orderRandomAdmin: (state) => {
            function shuffleArray(questions){
                questions.sort(()=> Math.random() - 0.5);
            }
            shuffleArray(state.adminFilteredQuestions)
        },
        orderRecents: (state, payload) => {
            const recents = [...state.questions]
            const recentsReversed = recents.reverse()
            const order = payload = 'recientes' ? recentsReversed : state.filteredQuestions
            return {
                ...state,
                filteredQuestions: order
            }
        },
/*         orderRecentsAdmin: (state, payload) => {
            const recents = [...state.allQuestions]
            const recentsReversed = recents.reverse()
            const order = payload = 'recientes' ? recentsReversed : state.allQuestions
            return {
                ...state,
                adminFilteredQuestions: order
            }
        }, */
    }
})

export const { getAllQuestions, getQuestions, getFilteredQuestions, getUserIp, clearQuestions, filterByType, filterMyQuestions, orderByLikes, orderByLikesAdmin, orderByDislikes, orderByDislikesAdmin, orderRandom, orderRandomAdmin, orderRecents, orderRecentsAdmin } = questionSlice.actions
export default questionSlice.reducer