import "./MiniSpinner.css";

export default function MiniSpinner() {
return (
    <div className="loadingMiniContainer">
        <div className="loaderMini">
            <div></div>
        </div>
    </div>
    );
}