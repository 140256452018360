import { createSlice } from "@reduxjs/toolkit";

export const unconfirmedQuestionSlice = createSlice({
    name: "unconfirmedQuestions",
    initialState: {
        allUnconfirmedQuestions: [],
        filteredUnconfirmedQuestions: [],
    },
    reducers: {
        getAllUnconfirmedQuestions: (state, action) => {
            state.allUnconfirmedQuestions = action.payload
            state.filteredUnconfirmedQuestions = action.payload
        },
        filterUnconfirmedByType: (state, {payload}) => {
            const questionType = state.allUnconfirmedQuestions
            const filter = payload === 'All' ? state.allUnconfirmedQuestions : questionType.filter(q => ((q.type) || []).includes(payload))
            return {
                ...state,
                filteredUnconfirmedQuestions: filter
            }
        },
    }
})

export const { getAllUnconfirmedQuestions, filterUnconfirmedByType} = unconfirmedQuestionSlice.actions
export default unconfirmedQuestionSlice.reducer