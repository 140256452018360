import { useEffect, useState } from 'react'
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode'
import { signGoogle } from '../../redux/actions/authActions';
import { useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MiniSpinner from './Spinner/MiniSpinner';
import Swal from 'sweetalert2';

export default function Auth({closeModal, openNickNameModal}) {

    const dispatch = useDispatch()
    const navigateTo = useNavigate()
    const [loading, setLoading] = useState(false)

    const googleSuccess = async(res) => {
        const token = res.credential
        const googleUser = (jwt_decode(token))
        try {
            dispatch(signGoogle(googleUser, closeModal, openNickNameModal, setLoading, navigateTo))
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            {
                loading ? 
                <MiniSpinner /> :
                <GoogleLogin
                client_id={process.env.REACT_APP_CLIENT_ID}
                onSuccess={googleSuccess}
                onError={() => {
                    Swal.fire({
                        title: "Error",
                        text: "Algo falló, prueba después!",
                        icon: "error",
                        background: "#1a1a1a",
                        color: '#fff',
                    })
                }}
            />
            }
        </div>
    )
}