import { useDispatch } from "react-redux";
import { orderByLikesAdmin, orderByDislikesAdmin, orderRecentsAdmin, orderOlds, orderRandomAdmin } from "../../../redux/slices/questionSlice"

export default function AdminSortQuestions({setPage}) {

    let dispatch = useDispatch()

    function handleChange(e){
        const value = e.target.value;
        if(value === "aleatorio") {
            setPage(1)
            dispatch(orderRandomAdmin())
        }
        if(value === "menos_likes" || value === "mas_likes") {
            setPage(1)
            dispatch(orderByLikesAdmin(value))
        } 
        if(value === "menos_dislikes" || value === "mas_dislikes") {
            setPage(1)
            dispatch(orderByDislikesAdmin(value))
        } 
        /* if(value === "recientes") {
            setPage(1)
            dispatch(orderRecentsAdmin())
        } */
    } 

    return (
        <div className="select mt-3 mr-2" style={{width: '150px', height: '30px', marginLeft: '10px'}}>
            <select className="ms-2 mr-3" defaultValue="antiguas" onChange={handleChange}>
                <option style={{textAlign: 'center'}} value="antiguas" disabled> Antiguas </option>
                <option style={{textAlign: 'center'}} value="aleatorio"> Aleatorio </option>
                {/* <option style={{textAlign: 'center'}} value="recientes" > Recientes </option> */}
                <option style={{textAlign: 'center'}} value="mas_likes"> Más likes</option>
                <option style={{textAlign: 'center'}} value="menos_likes"> Menos likes</option>
                <option style={{textAlign: 'center'}} value="mas_dislikes" > Más dislikes</option>
                <option style={{textAlign: 'center'}} value="menos_dislikes" > Menos dislikes</option>
            </select>
        </div>
    )
}