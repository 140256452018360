import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import './App.css';
import Personales from './components/questions/Personales'
import Profundas from './components/questions/Profundas'
import Casuales from './components/questions/Casuales'
import PicantesGrupales from './components/questions/PicantesGrupales'
import YoNuncaNunca from './components/questions/YoNuncaNunca'
import QuePrefieresSituaciones from './components/questions/QuePrefieresSituaciones'
import QuePrefieresCosas from './components/questions/QuePrefieresCosas'
import AnecdotaDe from './components/questions/AnecdotaDe'
/* import SoyMasDe from './components/questions/SoyMasDe' */
/* import TopTres from './components/questions/TopTres' */
import RetoOShot from './components/questions/RetoOShot'
import Retos from './components/questions/Retos'
import QuienDeTusAmigosEs from './components/questions/QuienDeTusAmigosEs'
/* import MatarCogerOCasarse from './components/questions/MatarCogerOCasarse' */
import QuePrefieresBizarro from './components/questions/QuePrefieresBizarro'
/* import FrasesQueSePuedenDecirEnElSexoY from './components/questions/FrasesQueSePuedenDecirEnElSexoY' */
import { GoogleOAuthProvider } from '@react-oauth/google';
import QuestionsPanel from './components/admin/QuestionsPanel';
import { useEffect, useState } from 'react';
import UnconfirmedQuestionsPanel from './components/admin/UnconfirmedQuestionsPanel';
import DataPanel from './components/admin/DataPanel';
import UsersPanel from './components/admin/UsersPanel';


function App() {

  const [localUser, setLocalUser] = useState(JSON.parse(localStorage.getItem('profile')))
  const location = useLocation()

  useEffect(() => {
    setLocalUser(JSON.parse(localStorage.getItem('profile')))
  }, [location])

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <Routes>
        <Route exact path='/' element={<Personales />} />
        <Route exact path='/personales' element={<Personales />} />
        <Route exact path='/profundas' element={<Profundas />} />
        <Route exact path='/casuales' element={<Casuales />} />
        <Route exact path='/quePrefieresSituaciones' element={<QuePrefieresSituaciones />} />
        <Route exact path='/quePrefieresCosas' element={<QuePrefieresCosas />} />
        <Route exact path='/anecdota' element={<AnecdotaDe />} />
        {/* <Route exact path='/soyMasDe' element={<SoyMasDe />} /> */}
        {/* <Route exact path='/topTres' element={<TopTres />} /> */}
        <Route exact path='/retos' element={<Retos />} />
        <Route exact path='/quienDeTusAmigosEs' element={<QuienDeTusAmigosEs />} />
        {/* <Route exact path='/matarCogerOCasarse' element={<MatarCogerOCasarse />} /> */}
        {/* <Route exact path='/frasesQueSePuedenDecirEnElSexoY' element={<FrasesQueSePuedenDecirEnElSexoY />} /> */}
        <Route exact path='/picantes' element={<PicantesGrupales />} />
        <Route exact path='/yoNuncaNunca' element={<YoNuncaNunca />} />
        <Route exact path='/retoOShot' element={<RetoOShot />} />
        <Route exact path='quePrefieresBizarro' element={<QuePrefieresBizarro />} />
        <Route exact path='/panelDePreguntas' element={localUser?.result?.admin === true ? <QuestionsPanel /> : <Navigate to="/" replace />} />
        <Route exact path='/panelDePreguntasAConfirmar' element={localUser?.result?.admin === true ? <UnconfirmedQuestionsPanel /> : <Navigate to="/" replace />} />
        <Route exact path='/panelDeDatos' element={localUser?.result?.admin === true ? <DataPanel /> : <Navigate to="/" replace />} />
        <Route exact path='/panelDeUsuarios' element={localUser?.result?.admin === true ? <UsersPanel /> : <Navigate to="/" replace />} />
      </Routes>
    </GoogleOAuthProvider>
  );
}

export default App;
