import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAllUsers } from "../../redux/actions/userActions"
import AdminNavBar from "./AdminNavBar"
import { useState } from "react"
import Pagination from "../tools/Pagination"
import Swal from "sweetalert2"


export default function UsersPanel() {

    const dispatch = useDispatch()
    const [localUser, setLocalUser] = useState(JSON.parse(localStorage.getItem('profile')))
    const { allUsers } = useSelector(state => state.user)
    let [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(12)
    const [search, setSearch] = useState('')
    const filtered = allUsers.filter(u => u?.name?.toLowerCase().includes(search.toLowerCase()))
	const max = Math.ceil(allUsers?.length / perPage)

    useEffect(() => {
        dispatch(getAllUsers())
    }, [])

    function filteredUser() {
        if(search.length === 0) {
            return allUsers
        } 
        if(filtered.length === 0) {
            Swal.fire({
                title: "Error",
                text: 'Ese usuario no existe!',
                icon: "error",
                background: "#1a1a1a",
                color: '#fff',
                timer: 2000,
            });
            setPage(1)
            setSearch("")
        } 
        return filtered
    }

    function handleOnSearch(e) {
        setSearch(e.target.value)
        setPage(1) 
    }

    return (
        <div>
            <AdminNavBar />
            {  
                allUsers.length > 0 && localUser?.result?.admin === true ?
                <div className="nav justify-content-center mb-3">
                <label className="form-label mt-2">
                    <input style={{fontFamily: '"Oswald", arial, sans-serif'}} autoComplete="off" className="form-control" onChange={handleOnSearch} placeholder="Buscar usuario" type="text" value={search} />
                </label>
                <Pagination page={page} setPage={setPage} max={max} />
            </div>  : ''
            }
            {
				allUsers.length > 0 && localUser?.result?.admin === true ?
                    filteredUser()
					.slice((page - 1) * perPage, (page - 1) * perPage + perPage)
					.map((u, i) => {
						return (
                                <div className="card d-inline-flex ms-4 mb-3" style={{width: '19rem', height: 'auto', backgroundColor: '#1a1a1a', color: '#fff', marginLeft: '30px'}} key={i}>
                                    <div className="card-body">
                                            <h5 className="card-title mr-4" style={{color: '#df0e62'}}>{u?.name}</h5>
                                            <p> {u?.email}  </p>
                                    </div>
                                </div>
								)
					}) : <div className="d-flex justify-content-center"><h1 style={{color: '#df0e62'}}>Cargando...</h1></div>
			}
            {
                allUsers.length > 0 && localUser?.result?.admin === true ?
                <Pagination page={page} setPage={setPage} max={max} /> : ''
            }
        </div>
    )
}