import { configureStore } from "@reduxjs/toolkit";
import authSlice from '../slices/authSlice'
import userSlice from '../slices/userSlice'
import questionSlice from "../slices/questionSlice";
import unconfirmedQuestionSlice from "../slices/unconfirmedQuestionSlice";

export default configureStore({
    reducer: {
        auth: authSlice,
        user: userSlice,
        question: questionSlice,
        unconfirmedQuestion: unconfirmedQuestionSlice,
    }
})