import { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUnconfirmedQuestions } from "../../redux/actions/unconfirmedQuestionActions";
import { useState } from "react";


export default function AdminNavBar() {

    const dispatch = useDispatch()
    const { allUnconfirmedQuestions } = useSelector(state => state.unconfirmedQuestion)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(getUnconfirmedQuestions(setLoading))
    }, [])

    return (
        <div>
            <ul className="nav justify-content-center">
                <li className="nav-item">
                    <Link className="nav-link" to={`/`}>Inicio</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to={`/panelDePreguntas`}>Preguntas</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to={`/panelDePreguntasAConfirmar`}>Preguntas a confirmar {loading ? '' : allUnconfirmedQuestions.length}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to={`/panelDeDatos`}>Datos</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to={`/panelDeUsuarios`}>Usuarios</Link>
                </li>
            </ul>
        </div>
    )
}