import { useEffect, useReducer, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useModal } from "./Modals/useModal"
import { addDislike, addLike, getIp } from "../../redux/actions/questionActions"
import { addUnconfirmedQuestion } from "../../redux/actions/unconfirmedQuestionActions"
import NavBar from "./NavBar"
import Modals from "./Modals/Modals"
import { TiDelete } from 'react-icons/ti'
import { getUserById } from "../../redux/actions/userActions"
import AuthModals from "./Modals/AuthModals"
import SortQuestions from "./sort/SortQuestions"
import { useLocation } from "react-router-dom"
import Spinner from "./Spinner"
import MiniSpinner from "./Spinner/MiniSpinner"
import Like from "../../assets/Like.png"
import Dislike from "../../assets/Dislike.png"
import Swal from "sweetalert2"

export default function QuestionsContainer({filteredQuestions}) {

    const dispatch = useDispatch()
	const { ip } = useSelector(state => state.question)
	const localUser = JSON.parse(localStorage.getItem('profile'))
	const { user } = useSelector(state => state.user)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
	const max = Math.ceil(filteredQuestions?.length / perPage)
	const initialState = { text: '', type: filteredQuestions[0]?.type, user: user?.name, userId: localUser?.result?._id }
	const [questionData, setQuestionData] = useState(initialState)
	const [isOpenModal, openModal, closeModal] = useModal(false);
	const [isOpenNickNameModal, openNickNameModal, closeNickNameModal] = useModal(false);
	const [isOpenQuestionModal, openQuestionModal, closeQuestionModal] = useModal(false);
	const objectIp = {ip: ip}
	const location = useLocation()
	const [loading, setLoading] = useState(false)
	const [existingName, setExistingName] = useState(false)

	useEffect(() => {
		if(!ip) {
            dispatch(getIp())
		}
		if(localUser) {
            dispatch(getUserById(localUser.result._id))
		}
	}, [location])

	if(user && localUser && !user.name && existingName === false) {
		openNickNameModal()
		setExistingName(true)
	}

	const handleChange = (e) => {
        setQuestionData({type: filteredQuestions[0]?.type, user: user?.name, userId: user?._id, [e.target.name]: e.target.value})
    }

	const handleSubmit = (e) => {
        e.preventDefault()
		if(questionData.text.length < 4) {
			Swal.fire({
				title: "Error",
				text: "Debe tener un mínimo de 4 caracteres!",
				icon: "error",
				background: "#1a1a1a",
				color: '#fff',
				timer: 3000,
			})
		} else if(questionData.text.length > 200) {
            Swal.fire({
				title: "Error",
				text: "Debe tener un máximo de 200 caracteres!",
				icon: "error",
				background: "#1a1a1a",
				color: '#fff',
				timer: 3000,
			})
		} else {
            dispatch(addUnconfirmedQuestion(questionData, closeQuestionModal, e, setQuestionData, initialState, setLoading))
		}
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <NavBar />
                {
				filteredQuestions.length > 0 ?
					filteredQuestions
						?.slice((page - 1) * perPage, (page - 1) * perPage + perPage)
						?.map((q, i) => {
							let _id = q._id

                            function addLikeFunction() {
								if(q.ips.includes(ip)) {
									if(page < max) {
										setPage(page + 1)
									}
								} else {
                                    dispatch(addLike(_id, page, max, setPage, objectIp))
								}
							}

							function addDislikeFunction() {
								if(q.ips.includes(ip)) {
									if(page < max) {
										setPage(page + 1)
									}
								} else {
                                    dispatch(addDislike(_id, page, max, setPage, objectIp))
								}
							}

							return (
                                <div className="col-lg-8 col-12" style={{marginTop: '7%'}} key={i}>
                                    <section className="site-section darken-bg" id="section-bio" style={{borderRadius: '8px', maxHeight: '620px'}}>
	                                    <div className="container">
		                                    <div className="row justify-content-center">
												<SortQuestions setPage={setPage} filteredQuestions={filteredQuestions} />
						                        <div className="col-xl-10 col-lg-10 col-md-10 col-12 px-4">
								                    <h2 className="text-white mb-3">{q.type} </h2>
				                                    <div className="button">
					                                    <div className="card-question mb-3 py-3">
						                                    <h3 style={{marginBottom: '-0.1rem'}} className="text-white text-center" id="question_description">{q.text}</h3>
															{
										                    q.user ? <p className="questionUser">@{q.user} </p> : ''
									                        }
					                                    </div>
														<div>
															<div className="likesDiv">
																<p className="text-center button"><button onClick={addLikeFunction} className="btn btn-primary smoothscroll"><img style={{height: '60px', width: '70px'}} src={Like} alt="like" /></button></p>
																<p className="text-center button"><button onClick={addDislikeFunction} className="btn btn-primary smoothscroll likesButtons"><img style={{height: '60px', width: '70px'}} src={Dislike} alt="like" /></button></p>
															</div>
														</div>
											            <p className="text-center button"><button className="btn btn-primary btn-md" style={{fontFamily: "'Open Sans', sans-serif"}} onClick={localUser !== null ? openQuestionModal : openModal}>Agregar Pregunta</button></p>
				                                    </div>
							                    </div>
					                        </div>
	                                    </div>
                                    </section>
                                </div>
								)
							}) : <Spinner />
				}
				<AuthModals isOpenModal={isOpenModal} closeModal={closeModal} isOpenNickNameModal={isOpenNickNameModal} openNickNameModal={openNickNameModal} closeNickNameModal={closeNickNameModal} />
				<Modals isOpenModal={isOpenQuestionModal} closeModal={closeQuestionModal}>
					<div className="d-flex">
					    <p style={{color: '#df0e62', marginLeft: '50px'}}>Agrega tu pregunta</p>
					    <div>
							<button onClick={closeQuestionModal} style={{background: 'none', border: 'none', cursor: 'pointer', marginLeft: '25px', outline: 'none'}}>
								<TiDelete size={25} color="red" />
							</button>
						</div>
					</div>
                    <h4>{`Agrega tu pregunta a la sección ${filteredQuestions[0]?.type}`}</h4>
					{
						loading ?
                        <MiniSpinner /> :
                        <form onSubmit={handleSubmit} noValidate>
					        <textarea style={{backgroundColor: '#686868', borderRadius: '5px', textAlign: 'center', height: '87px', width: '320px', resize: 'none'}} autoComplete='off' name="text" className="form-control nickNameInput" placeholder="Escribe tu pregunta" onChange={handleChange} />
						<button style={{borderRadius: '5px', textDecoration: 'none'}} className="btn btn-success mt-2" type="submit">Enviar</button>
					</form>
					}
                </Modals>
            </div>
        </div>
    )
}